import './styles/shapyaMaxWidth.css';
import './styles/NotFound.css';

import { Box, Hidden } from '@material-ui/core';

import ImgEgg from './imgComponents/imgEgg';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotFoundComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box align="center" className="mainBox404">
        <Box>
          <ImgEgg />
          <Hidden mdDown>
            <br />
          </Hidden>
          <br />
          <p className="maintext404 shapyaText">Oh no!</p>
          <Hidden mdDown>
            <br />
          </Hidden>
          <br />
          <p className="notfoundtext404">{t('404.notfound')}</p>
          <Hidden mdDown>
            <br />
            <br />
          </Hidden>
          <br />
          <p className="notfoundback404">
            {t('404.text')}{' '}
            <Link to="/" className="notfoundback404">
              <u>{t('404.buttongoback')}</u>
            </Link>
          </p>
        </Box>
      </Box>
    </div>
  );
};

export default NotFoundComponent;
