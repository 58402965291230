import '../i18n';

import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import NavbarWhite from '../components/navbar/NavbarWhite';
import NotFoundComponent from '../components/NotFoundComponent';
import React from 'react';
import { t } from 'i18next';

const NotFoundPage = () => (
  <React.Fragment>
    <Helmet>
      <title>404: Not found</title>
    </Helmet>
    <NavbarWhite />
    <NotFoundComponent />
    <Footer />
  </React.Fragment>
);

export default NotFoundPage;
